/*
// .filter-price
*/
@import '../variables';


.filter-price {}
.filter-price__slider {
    margin-top: 34px;
    margin-bottom: 34px;
}
.filter-price__title {
    margin-top: 16px;
    font-size: 14px;
    color: $card-font-muted-color;
}
.filter-price__title1 {
    vertical-align: middle;
    font-size: 14px;
    padding-right: -40px;
    color: $card-font-muted-color;
}